import React from 'react';
import PropTypes from 'prop-types';

const PayloadIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      className={className}
      viewBox="0 0 24 24"
    >
      <g fill={color} fillRule="evenodd" clipPath="url(#prefix__clip0)" clipRule="evenodd">
        <path d="M0 14.5c.828 0 1.5.672 1.5 1.5v5.333c0 .31.123.607.342.825.219.22.515.342.825.342h18.666c.31 0 .607-.123.825-.342.22-.219.342-.515.342-.825V16c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5v5.333c0 1.105-.439 2.165-1.22 2.947-.782.781-1.842 1.22-2.947 1.22H2.667c-1.105 0-2.165-.439-2.947-1.22-.781-.782-1.22-1.842-1.22-2.947V16c0-.828.672-1.5 1.5-1.5z" />
        <path d="M4.273 8.273c.586-.586 1.535-.586 2.121 0L12 13.879l5.606-5.606c.586-.586 1.536-.586 2.121 0 .586.586.586 1.535 0 2.121l-6.666 6.667c-.586.586-1.536.586-2.121 0l-6.667-6.667c-.586-.586-.586-1.535 0-2.121z" />
        <path d="M12-1.5c.828 0 1.5.672 1.5 1.5v16c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5V0c0-.828.672-1.5 1.5-1.5z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h24v24H0V0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

PayloadIcon.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

PayloadIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default PayloadIcon;
