import React from 'react';
import PropTypes from 'prop-types';

const TripleFlagIcon = ({ size, className, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill={color}
      stroke={color}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M15 6.25C16.4508 6.25 17.5236 6.48161 19.2311 7.11223L19.6369 7.26491L20.8404 7.73864C22.7824 8.50199 23.7254 8.75 25 8.75C26.4858 8.75 27.6174 8.54425 28.442 8.2144C28.7621 8.08639 28.9598 7.97588 29.0548 7.91258L29.1161 7.86612L29.2218 7.77097C29.9819 7.15742 31.1389 7.63667 31.2425 8.60796L31.25 8.75V23.75C31.25 24.0815 31.1183 24.3995 30.8839 24.6339C30.6094 24.9084 30.1156 25.2375 29.3705 25.5356C28.242 25.987 26.7955 26.25 25 26.25V23.75C26.4858 23.75 27.6174 23.5442 28.442 23.2144L28.5919 23.152L28.75 23.0787V10.7537L28.464 10.8377C27.7272 11.0391 26.884 11.173 25.9287 11.2253L25.4416 11.2446L25 11.25C23.5492 11.25 22.4764 11.0184 20.7689 10.3878L20.3631 10.2351L19.1596 9.76136C17.2176 8.99801 16.2746 8.75 15 8.75C13.5142 8.75 12.3826 8.95575 11.558 9.2856L11.4081 9.34801L11.25 9.42L11.2509 11.5562C10.5434 11.3751 9.90225 11.2832 9.18825 11.2576L8.75 11.25V8.75C8.75 8.41848 8.8817 8.10054 9.11612 7.86612C9.39059 7.59165 9.88436 7.26247 10.6295 6.9644C11.758 6.513 13.2045 6.25 15 6.25Z" />
      <path d="M23.75 0C25.2008 0 26.2736 0.231614 27.9811 0.862228L28.3869 1.01491L29.5904 1.48864C31.5324 2.25199 32.4754 2.5 33.75 2.5C35.2358 2.5 36.3674 2.29425 37.192 1.9644C37.5121 1.83639 37.7098 1.72588 37.8048 1.66258L37.8661 1.61612L37.9718 1.52097C38.7319 0.907424 39.8889 1.38667 39.9925 2.35796L40 2.5V17.5C40 17.8315 39.8683 18.1495 39.6339 18.3839C39.3594 18.6584 38.8656 18.9875 38.1205 19.2856C36.992 19.737 35.5455 20 33.75 20V17.5C35.2358 17.5 36.3674 17.2942 37.192 16.9644L37.3419 16.902L37.5 16.8288V4.50375L37.214 4.58766C36.4772 4.78911 35.634 4.92295 34.6787 4.97527L34.1916 4.99456L33.75 5C32.2992 5 31.2264 4.76839 29.5189 4.13777L29.1131 3.98509L27.9096 3.51136C25.9676 2.74801 25.0246 2.5 23.75 2.5C22.2642 2.5 21.1326 2.70575 20.308 3.0356L20.1581 3.09801L20 3.17L20.0009 5.30623C19.2934 5.12506 18.6522 5.03318 17.9383 5.00759L17.5 5V2.5C17.5 2.16848 17.6317 1.85054 17.8661 1.61612C18.1406 1.34165 18.6344 1.01247 19.3795 0.714404C20.508 0.262996 21.9545 0 23.75 0Z" />
      <path d="M0 15C0 14.6685 0.131696 14.3505 0.366117 14.1161C0.640588 13.8416 1.13436 13.5125 1.87951 13.2144C3.00803 12.763 4.45451 12.5 6.25 12.5C7.81239 12.5 8.93636 12.7686 10.8869 13.5149L12.0904 13.9886C14.0324 14.752 14.9754 15 16.25 15C17.7358 15 18.8674 14.7942 19.692 14.4644C20.1187 14.2937 20.3281 14.1541 20.3661 14.1161L20.4718 14.021C21.2681 13.3782 22.5 13.9348 22.5 15V30C22.5 30.3315 22.3683 30.6495 22.1339 30.8839C21.8594 31.1584 21.3656 31.4875 20.6205 31.7856C19.492 32.237 18.0455 32.5 16.25 32.5C14.6876 32.5 13.5636 32.2314 11.6131 31.4851L10.4096 31.0114C8.46763 30.248 7.52463 30 6.25 30C4.76424 30 3.63259 30.2058 2.80799 30.5356C2.68812 30.5835 2.58541 30.629 2.49883 30.6705L2.5 38.75C2.5 39.4404 1.94036 40 1.25 40C0.608955 40 0.080616 39.5175 0.00840966 38.8958L0 38.75V15ZM6.25 15C4.76424 15 3.63259 15.2058 2.80799 15.5356C2.70131 15.5783 2.60821 15.619 2.52799 15.6567L2.5 15.67V27.995L2.786 27.9123C3.64563 27.6773 4.65002 27.5343 5.80838 27.5054L6.25 27.5C7.81239 27.5 8.93636 27.7686 10.8869 28.5149L12.0904 28.9886C14.0324 29.752 14.9754 30 16.25 30C17.7358 30 18.8674 29.7942 19.692 29.4644C19.7987 29.4217 19.8918 29.381 19.972 29.3433L20 29.3288V17.0037L19.714 17.0877C18.8544 17.3227 17.85 17.4657 16.6916 17.4946L16.25 17.5C14.6876 17.5 13.5636 17.2314 11.6131 16.4851L10.4096 16.0114C8.46763 15.248 7.52463 15 6.25 15Z" />
    </svg>
  );
};

TripleFlagIcon.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

TripleFlagIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default TripleFlagIcon;
