import PropTypes from 'prop-types';

const PackageIcon = ({ size, className, color }) => {
  return (
    <svg
      width={size}
      height={size}
      className={className}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.25 38.0995L33.75 30.3145"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54 47.9999V35.9999C53.9989 34.9292 53.4273 33.9402 52.5 33.4049L42 27.4049C41.0718 26.869 39.9282 26.869 39 27.4049L28.5 33.4049C27.5727 33.9402 27.0011 34.9292 27 35.9999V47.9999C27.0011 49.0706 27.5727 50.0595 28.5 50.5949L39 56.5949C39.9282 57.1308 41.0718 57.1308 42 56.5949L52.5 50.5949C53.4273 50.0595 53.9989 49.0706 54 47.9999Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.4048 34.4395L40.4998 42.0145L53.5948 34.4395"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M40.5 57.12V42" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

PackageIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

PackageIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default PackageIcon;
