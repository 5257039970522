import React from 'react';
import PropTypes from 'prop-types';

const ReplacementIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      fill={color}
      viewBox="0 0 28 34"
    >
      <g fill="#4D3881">
        <path d="M5 6.5c-.828 0-1.5.672-1.5 1.5v21c0 .828.672 1.5 1.5 1.5h18c.828 0 1.5-.672 1.5-1.5V8c0-.828-.672-1.5-1.5-1.5h-3c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5h3c2.485 0 4.5 2.015 4.5 4.5v21c0 2.485-2.015 4.5-4.5 4.5H5C2.515 33.5.5 31.485.5 29V8C.5 5.515 2.515 3.5 5 3.5h3c.828 0 1.5.672 1.5 1.5S8.828 6.5 8 6.5H5z" />
        <path d="M6.5 3.5c0-1.657 1.343-3 3-3h9c1.657 0 3 1.343 3 3v3c0 1.657-1.343 3-3 3h-9c-1.657 0-3-1.343-3-3v-3zm12 0h-9v3h9v-3zM19.06 14.94c.586.585.586 1.535 0 2.12l-6 6c-.585.586-1.535.586-2.12 0l-3-3c-.586-.585-.586-1.535 0-2.12.585-.586 1.535-.586 2.12 0L12 19.878l4.94-4.94c.585-.585 1.535-.585 2.12 0z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M.5.5h27v33H.5V.5z" />
        </clipPath>
      </defs>
    </svg>
  );
};

ReplacementIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

ReplacementIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default ReplacementIcon;
