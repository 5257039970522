import React from 'react';
import PropTypes from 'prop-types';

const CoarseIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      viewBox="0 0 41 43"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M3.5 30.5h9v9M37.5 12.5h-9v-9M28.5 12.5L39 2M2 41l10.5-10.5"
      />
      <path fill={color} d="M21 28c3.314 0 6-2.686 6-6s-2.686-6-6-6-6 2.686-6 6 2.686 6 6 6z" />
    </svg>
  );
};

CoarseIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

CoarseIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default CoarseIcon;
