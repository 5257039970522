// eslint-disable-next-line import/prefer-default-export
export { default as EndEffectorIcon } from './end-effector';
export { default as TrendingUpIcon } from './trending-up';
export { default as TripleFlagIcon } from './triple-flag';
export { default as UsersIcon } from './users';
export { default as IndustryIcon } from './industry';
export { default as EducationIcon } from './education';
export { default as MeasureIcon } from './measure';
export { default as PayloadIcon } from './payload';
export { default as CoarseFineIcon } from './coarse-fine';
export { default as CoarseMediumIcon } from './coarse-medium';
export { default as CoarseIcon } from './coarse';
export { default as CoarseLargeIcon } from './coarse-large';

export { default as ReachSmallIcon } from './reach-small';
export { default as ReachMediumIcon } from './reach-medium';
export { default as ReachLargeIcon } from './reach-large';

export { default as ClipboardCheckmarkIcon } from './clipboard-checkmark';
export { default as ReplacementIcon } from './replacement';
export { default as WorkflowIcon } from './workflow';
export { default as CylinderIcon } from './cylinder';

export { default as StopwatchFastIcon } from './stopwatch-fast';
export { default as StopwatchMediumIcon } from './stopwatch-medium';
export { default as StopwatchSlowIcon } from './stopwatch-slow';

export { default as RepeatOnceIcon } from './repeat-once';
export { default as RepeatTwiceIcon } from './repeat-twice';
export { default as RepeatThriceIcon } from './repeat-thrice';

export { default as MultiPartIcon } from './multi-part';
export { default as PackageIcon } from './package';
export { default as WizardSuccessIcon } from './wizard-success';

export { default as PlusIcon } from './plus';
export * from './pick-and-place';
export * from './paletize';
export * from './labratory';
export * from './dispensing';
export * from './tractor';
export * from './sliders';
export * from './service';
