import React from 'react';
import PropTypes from 'prop-types';

const CoarseIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      viewBox="0 0 41 43"
    >
      <path
        fill={color}
        d="M18.5 24c2.485 0 4.5-2.015 4.5-4.5S20.985 15 18.5 15 14 17.015 14 19.5s2.015 4.5 4.5 4.5z"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M3.5 26.5h9v9M33.5 12.5h-9v-9M24.5 12.5L35 2M2 37l10.5-10.5"
      />
    </svg>
  );
};

CoarseIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

CoarseIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default CoarseIcon;
