import React from 'react';
import PropTypes from 'prop-types';

const RepeatOnce = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      viewBox="0 0 33 31"
    >
      <path
        fill={color}
        d="M31.495 1.499c.78 0 1.42.595 1.493 1.356l.007.144v9c0 .78-.595 1.42-1.355 1.493l-.145.007h-9c-.828 0-1.5-.671-1.5-1.5 0-.78.595-1.42 1.356-1.493l.144-.007h7.5v-7.5c0-.78.595-1.42 1.356-1.493l.144-.007z"
      />
      <path
        fill={color}
        d="M7.758 1.867c5.747-3.169 12.87-2.23 17.567 2.252l.253.248 6.945 6.54c.604.568.632 1.518.064 2.12-.532.566-1.4.627-2.003.164l-.117-.1-6.978-6.571c-3.778-3.781-9.602-4.607-14.282-2.026-4.68 2.58-7.09 7.946-5.91 13.159 1.181 5.213 5.667 9.017 11.003 9.329 5.336.312 10.235-2.943 12.016-7.982.276-.782 1.133-1.191 1.914-.915.78.276 1.19 1.133.915 1.914-2.226 6.3-8.35 10.368-15.02 9.978-6.67-.39-12.277-5.145-13.753-11.661C-1.104 11.8 1.908 5.093 7.758 1.866z"
      />
    </svg>
  );
};

RepeatOnce.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

RepeatOnce.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default RepeatOnce;
