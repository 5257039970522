import React from 'react';
import PropTypes from 'prop-types';

const RepeatThrice = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      fill={color}
      viewBox="0 0 45 45"
    >
      <path
        fill={color}
        d="M42.542 1.85c.78 0 1.42.595 1.493 1.356l.007.145v9c0 .78-.595 1.42-1.355 1.493l-.145.007h-9c-.828 0-1.5-.672-1.5-1.5 0-.78.595-1.42 1.355-1.494l.145-.006h7.5v-7.5c0-.78.595-1.42 1.355-1.494l.145-.006z"
      />
      <path
        fill={color}
        d="M22.723.724c4.907-1.11 10.034.314 13.632 3.745l.254.248 6.96 6.54c.603.568.633 1.517.066 2.12-.532.567-1.4.628-2.003.166l-.118-.1-6.993-6.572c-2.913-2.914-7.117-4.13-11.136-3.221-.808.183-1.611-.324-1.794-1.132-.183-.808.324-1.611 1.132-1.794z"
      />
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M30.056 41.418l-7.795-4.5 4.5-7.794"
      />
      <path
        fill={color}
        d="M42.24 25.569c-1.492 4.804-5.29 8.533-10.059 9.932l-.342.096-9.144 2.758c-.793.239-1.63-.21-1.87-1.003-.224-.744.157-1.526.86-1.818l.144-.051 9.189-2.77c3.98-1.066 7.135-4.1 8.357-8.034.246-.791 1.086-1.233 1.877-.988.791.246 1.234 1.087.988 1.878zM2.229 11.59c-.39-.675-.196-1.527.427-1.97l.122-.079 7.794-4.5c.675-.39 1.527-.195 1.97.428l.079.121 4.5 7.795c.414.717.168 1.634-.55 2.049-.675.39-1.527.195-1.97-.428l-.078-.121-3.75-6.495-6.495 3.75c-.676.39-1.528.194-1.971-.428l-.078-.122z"
      />
      <path
        fill={color}
        d="M11.162 29.317C7.747 25.623 6.417 20.47 7.59 15.639l.088-.344L9.86 5.997c.19-.806.997-1.307 1.804-1.117.756.177 1.243.898 1.144 1.652l-.027.151-2.195 9.343c-1.068 3.98-.019 8.229 2.778 11.254.562.609.525 1.558-.083 2.12-.609.562-1.558.525-2.12-.083z"
      />
    </svg>
  );
};

RepeatThrice.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

RepeatThrice.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default RepeatThrice;
