import React from 'react';
import PropTypes from 'prop-types';

const RepeatTwice = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      viewBox="0 0 37 30"
    >
      <path
        fill={color}
        d="M35.003 1.497c.78 0 1.42.595 1.493 1.356l.007.144v9c0 .78-.595 1.42-1.356 1.493l-.144.007h-9c-.829 0-1.5-.671-1.5-1.5 0-.78.595-1.42 1.355-1.493l.145-.007h7.5v-7.5c0-.78.595-1.42 1.355-1.493l.145-.007zM11.003 16.497c.828 0 1.5.672 1.5 1.5 0 .78-.595 1.42-1.356 1.493l-.144.007h-7.5v7.5c0 .78-.595 1.42-1.356 1.493l-.144.007c-.78 0-1.42-.595-1.493-1.355l-.007-.145v-9c0-.78.595-1.42 1.355-1.493l.145-.007h9z"
      />
      <path
        fill={color}
        d="M2.913 16.805l.117.099 6.994 6.573c2.913 2.914 7.117 4.13 11.136 3.22 4.018-.909 7.29-3.816 8.664-7.7.276-.781 1.133-1.19 1.914-.914.781.276 1.19 1.134.914 1.915-1.718 4.855-5.807 8.49-10.83 9.626-4.907 1.11-10.035-.314-13.632-3.745l-.254-.249-6.96-6.54c-.604-.567-.633-1.516-.066-2.12.496-.528 1.285-.617 1.879-.25l.124.085zm25.903-12.69l.254.249 6.96 6.54c.604.567.633 1.517.066 2.12-.532.566-1.4.628-2.003.166l-.117-.1-6.994-6.573c-2.913-2.914-7.117-4.13-11.136-3.22-4.018.909-7.29 3.816-8.664 7.7-.276.781-1.133 1.19-1.914.914-.781-.276-1.19-1.133-.914-1.914C6.072 5.14 10.16 1.507 15.184.37 19.971-.713 24.97.617 28.55 3.868l.266.247z"
      />
    </svg>
  );
};

RepeatTwice.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

RepeatTwice.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default RepeatTwice;
