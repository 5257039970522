import React from 'react';
import PropTypes from 'prop-types';

const ReachLargeIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      fill="none"
      viewBox="0 0 80 80"
    >
      <path
        d="M60.5 56C60.5 58.4853 62.5147 60.5 65 60.5C67.4853 60.5 69.5 58.4853 69.5 56C69.5 53.5147 67.4853 51.5 65 51.5C62.5147 51.5 60.5 53.5147 60.5 56Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.5 38C35.5 40.4853 37.5147 42.5 40 42.5C42.4853 42.5 44.5 40.4853 44.5 38C44.5 35.5147 42.4853 33.5 40 33.5C37.5147 33.5 35.5 35.5147 35.5 38Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 56C12 58.4853 14.0147 60.5 16.5 60.5C18.9853 60.5 21 58.4853 21 56C21 53.5147 18.9853 51.5 16.5 51.5C14.0147 51.5 12 53.5147 12 56Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36 41L20.265 53.63"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.5 53.2695L44.765 40.6395"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M26 56L25 56" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M32 56L31 56" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M38 56L37 56" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M44 56L43 56" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M50 56L49 56" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M56 56L55 56" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

ReachLargeIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

ReachLargeIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default ReachLargeIcon;
