import type { IconProps } from 'react-feather';

export const DispensingIcon = (props: IconProps) => (
  <svg width="32" height="23" viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.1799 19.0128C12.5898 18.6758 13.1886 18.7433 13.5174 19.1635C13.8461 19.5837 13.7803 20.1975 13.3704 20.5345C11.4825 22.0865 10.897 22.9148 8.81579 22.9937L8.48646 23H5.678C5.15254 23 4.72656 22.5633 4.72656 22.0247C4.72656 21.5245 5.09386 21.1123 5.56705 21.0559L5.678 21.0494H8.48646C10.2322 21.0494 10.5162 20.3805 12.1799 19.0128Z"
      fill="currentColor"
    />
    <path
      d="M24.09 0.365845L17.7294 6.88591C17.2621 7.36491 17.1392 7.91208 17.0642 9.08651L17.0119 9.99288C17.0036 10.1139 16.9954 10.2099 16.9861 10.2945L16.9488 10.5717C16.9483 10.5944 16.9551 10.599 16.9763 10.5817L15.0513 12.5571C14.9294 12.6821 14.8429 12.8387 14.801 13.0102L13.8496 16.9114C13.6754 17.6257 14.3066 18.2727 15.0034 18.0942L18.8091 17.1188C18.9764 17.076 19.1292 16.9873 19.2512 16.8623L21.2909 14.7702L21.3606 14.7591L21.6275 14.733C21.703 14.7283 21.7894 14.7246 21.8966 14.7214L22.4553 14.7097C23.7769 14.6843 24.3056 14.607 24.7951 14.1052L31.1442 7.59694C32.0224 6.69668 31.259 5.58579 29.0833 3.3239L28.2585 2.47835C26.0519 0.248113 24.9682 -0.534411 24.09 0.365845ZM19.075 8.26519L25.0111 2.18001L25.1032 2.24756C25.1802 2.30513 25.2638 2.3702 25.353 2.44219C25.8413 2.83621 26.459 3.405 27.0898 4.03861L27.3267 4.27904C28.038 5.00815 28.6862 5.73908 29.1186 6.30221L29.265 6.49784L29.3734 6.65177L23.4496 12.7259C23.4779 12.6969 23.2481 12.7248 22.8368 12.745L22.2581 12.7624L21.6384 12.7776C20.718 12.8115 20.423 12.9025 20.0421 13.293L18.0922 15.291L16.0799 15.8069L16.5832 13.7442L18.348 11.9363C18.7783 11.4952 18.8546 11.1229 18.9259 9.87652L18.9496 9.44057C18.9912 8.69052 19.0668 8.27361 19.075 8.26519Z"
      fill="currentColor"
    />
  </svg>
);
