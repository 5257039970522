import type { IconProps } from 'react-feather';

export const PaletizeIcon = (props: IconProps) => (
  <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.5 1L1.83301 6.8335L13.5 12.667L25.167 6.8335L13.5 1Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.83301 18.5005L13.5 24.334L25.167 18.5005"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.83301 12.667L13.5 18.5005L25.167 12.667"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
