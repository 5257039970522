import React from 'react';
import PropTypes from 'prop-types';

const ReplacementIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      viewBox="0 0 37 31"
    >
      <g fill="#4D3881">
        <path d="M.94 9.06c-.586-.585-.586-1.535 0-2.12l6-6c.585-.586 1.535-.586 2.12 0l6 6c.586.585.586 1.535 0 2.12-.585.586-1.535.586-2.12 0L8 4.122l-4.94 4.94c-.585.585-1.535.585-2.12 0z" />
        <path d="M9.5 23c0 2.485 2.015 4.5 4.5 4.5h3c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5h-3c-4.142 0-7.5-3.358-7.5-7.5V2c0-.828.672-1.5 1.5-1.5s1.5.672 1.5 1.5v21zM21.94 21.94c.585-.586 1.535-.586 2.12 0L29 26.878l4.94-4.94c.585-.585 1.535-.585 2.12 0 .586.586.586 1.536 0 2.122l-6 6c-.585.585-1.535.585-2.12 0l-6-6c-.586-.586-.586-1.536 0-2.122z" />
        <path d="M18.5 2c0-.828.672-1.5 1.5-1.5h3c4.142 0 7.5 3.358 7.5 7.5v21c0 .828-.672 1.5-1.5 1.5s-1.5-.672-1.5-1.5V8c0-2.485-2.015-4.5-4.5-4.5h-3c-.828 0-1.5-.672-1.5-1.5z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M.5 30.5V.5h36v30H.5z" />
        </clipPath>
      </defs>
    </svg>
  );
};

ReplacementIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

ReplacementIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default ReplacementIcon;
