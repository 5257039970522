import React from 'react';
import PropTypes from 'prop-types';

const MultiPartIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      className={className}
      viewBox="0 0 80 80"
    >
      <path
        d="M29.25 55.0995L15.75 47.3145"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 64.9999V52.9999C35.9989 51.9292 35.4273 50.9402 34.5 50.4049L24 44.4049C23.0718 43.869 21.9282 43.869 21 44.4049L10.5 50.4049C9.57275 50.9402 9.0011 51.9292 9 52.9999V64.9999C9.0011 66.0706 9.57275 67.0595 10.5 67.5949L21 73.5949C21.9282 74.1308 23.0718 74.1308 24 73.5949L34.5 67.5949C35.4273 67.0595 35.9989 66.0706 36 64.9999Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.40479 51.4395L22.4998 59.0145L35.5948 51.4395"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M22.5 74.12V59" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M64.25 54.0995L50.75 46.3145"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M71 63.9999V51.9999C70.9989 50.9292 70.4273 49.9402 69.5 49.4049L59 43.4049C58.0718 42.869 56.9282 42.869 56 43.4049L45.5 49.4049C44.5727 49.9402 44.0011 50.9292 44 51.9999V63.9999C44.0011 65.0706 44.5727 66.0595 45.5 66.5949L56 72.5949C56.9282 73.1308 58.0718 73.1308 59 72.5949L69.5 66.5949C70.4273 66.0595 70.9989 65.0706 71 63.9999Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.4048 50.4395L57.4998 58.0145L70.5948 50.4395"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M57.5 73.12V58" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M46.25 24.0995L32.75 16.3145"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53 33.9999V21.9999C52.9989 20.9292 52.4273 19.9402 51.5 19.4049L41 13.4049C40.0718 12.869 38.9282 12.869 38 13.4049L27.5 19.4049C26.5727 19.9402 26.0011 20.9292 26 21.9999V33.9999C26.0011 35.0706 26.5727 36.0595 27.5 36.5949L38 42.5949C38.9282 43.1308 40.0718 43.1308 41 42.5949L51.5 36.5949C52.4273 36.0595 52.9989 35.0706 53 33.9999Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.4048 20.4395L39.4998 28.0145L52.5948 20.4395"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M39.5 43.12V28" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

MultiPartIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

MultiPartIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default MultiPartIcon;
