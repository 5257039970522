import React from 'react';
import PropTypes from 'prop-types';

const PlusIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      viewBox="0 0 80 80"
    >
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.5 40.5C41.3284 40.5 42 41.1716 42 42V63C42 63.8284 41.3284 64.5 40.5 64.5C39.6716 64.5 39 63.8284 39 63V42C39 41.1716 39.6716 40.5 40.5 40.5Z"
      />
      <path
        fill={color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.5 52.5C28.5 51.6716 29.1716 51 30 51H51C51.8284 51 52.5 51.6716 52.5 52.5C52.5 53.3284 51.8284 54 51 54H30C29.1716 54 28.5 53.3284 28.5 52.5Z"
      />
    </svg>
  );
};

PlusIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

PlusIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default PlusIcon;
