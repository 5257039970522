import type { IconProps } from 'react-feather';

export const LabratoryIcon = (props: IconProps) => (
  <svg width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.5 2V18.5C2.5 21.5376 4.96243 24 8 24C11.0376 24 13.5 21.5376 13.5 18.5V2H11.5V18.5C11.5 20.433 9.93299 22 8 22C6.067 22 4.5 20.433 4.5 18.5V2H2.5Z"
      fill="currentColor"
    />
    <line x1="3.5" y1="12" x2="11.5" y2="12" stroke="currentColor" strokeWidth="2" />
    <line x1="0.5" y1="1" x2="15.5" y2="1" stroke="currentColor" strokeWidth="2" />
  </svg>
);
