import React from 'react';
import type { IconProps } from 'react-feather';

const IndustryIcon = ({ size = 24, className, color = 'currentColor' }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      stroke={color}
      viewBox="0 0 76 68"
      strokeWidth="6"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <path d="M60 28L58.063 2H52M50 34l2-32" />
      <path d="M2 40l30-14 6 14 32-14 4 12.035V62c0 2.21-1.79 4-4 4H6c-2.21 0-4-1.79-4-4V40z" />
      <path d="M12 66V46h20v20M44 66V46h20v20" />
    </svg>
  );
};

export default IndustryIcon;
