import React from 'react';
import PropTypes from 'prop-types';

const ReachMediumIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      fill="none"
      viewBox="0 0 80 80"
    >
      <path
        d="M50 58.5C50 60.9853 52.0147 63 54.5 63C56.9853 63 59 60.9853 59 58.5C59 56.0147 56.9853 54 54.5 54C52.0147 54 50 56.0147 50 58.5Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 40.5C35 42.9853 37.0147 45 39.5 45C41.9853 45 44 42.9853 44 40.5C44 38.0147 41.9853 36 39.5 36C37.0147 36 35 38.0147 35 40.5Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 58.5C20 60.9853 22.0147 63 24.5 63C26.9853 63 29 60.9853 29 58.5C29 56.0147 26.9853 54 24.5 54C22.0147 54 20 56.0147 20 58.5Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M34 59L33 59" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M40 59L39 59" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M46 59L45 59" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M35.3525 44.3848L26.765 54.6298"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M52.2349 54.6148L43.9999 44.3848"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ReachMediumIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

ReachMediumIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default ReachMediumIcon;
