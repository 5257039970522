import React from 'react';
import PropTypes from 'prop-types';

const CylinderIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      fill="none"
      viewBox="0 0 34 38"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M4.97 8.494c0 .001 0 .008.004.023.005.016.018.05.048.099.064.104.194.26.437.451.495.39 1.305.807 2.44 1.185 2.252.751 5.46 1.241 9.07 1.241 3.612 0 6.82-.49 9.072-1.24 1.134-.379 1.944-.796 2.44-1.186.243-.191.372-.347.436-.451.03-.05.043-.083.048-.1.005-.014.005-.02.005-.022 0-.001 0-.008-.005-.023-.005-.017-.017-.05-.048-.1-.064-.103-.193-.259-.437-.45-.495-.39-1.305-.808-2.439-1.186-2.252-.75-5.46-1.24-9.071-1.24-3.612 0-6.82.49-9.072 1.24-1.134.378-1.944.795-2.439 1.185-.243.192-.373.348-.437.452-.03.05-.043.082-.048.099-.004.015-.004.021-.004.023zM6.95 3.89c2.633-.878 6.175-1.395 10.02-1.395 3.844 0 7.387.517 10.02 1.395 1.309.436 2.476.988 3.347 1.675.854.673 1.633 1.651 1.633 2.929 0 1.277-.779 2.256-1.633 2.929-.87.686-2.038 1.239-3.347 1.675-2.633.877-6.176 1.394-10.02 1.394-3.845 0-7.387-.517-10.02-1.394-1.31-.436-2.477-.989-3.348-1.675-.854-.673-1.632-1.652-1.632-2.93 0-1.277.778-2.255 1.632-2.928.871-.687 2.039-1.239 3.347-1.675z"
        clipRule="evenodd"
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M3.47 6.994c.828 0 1.5.671 1.5 1.5v20.995c0 .002 0 .01.005.027s.018.05.048.1c.063.104.192.26.433.45.491.39 1.296.806 2.426 1.183 2.246.75 5.453 1.24 9.088 1.24 3.635 0 6.841-.49 9.087-1.24 1.13-.377 1.935-.793 2.426-1.182.241-.191.37-.347.433-.45.03-.05.043-.084.049-.101.005-.017.005-.024.005-.027V8.493c0-.828.671-1.5 1.5-1.5.828 0 1.5.672 1.5 1.5V29.49c0 1.273-.77 2.252-1.623 2.928-.869.688-2.033 1.241-3.34 1.677-2.629.878-6.172 1.393-10.037 1.393-3.865 0-7.409-.515-10.038-1.393-1.307-.436-2.47-.989-3.34-1.677-.852-.676-1.622-1.655-1.622-2.928V8.493c0-.828.671-1.5 1.5-1.5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

CylinderIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

CylinderIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default CylinderIcon;
