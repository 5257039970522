import React from 'react';
import PropTypes from 'prop-types';

const CoarseLargeIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      className={className}
      viewBox="0 0 36 36"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M3.5 23.304h9v9M32.5 12.304h-9v-9M23.5 12.304L34 1.804M2 33.804l10.5-10.5"
      />
      <path fill={color} d="M18 20.804c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3z" />
    </svg>
  );
};

CoarseLargeIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

CoarseLargeIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default CoarseLargeIcon;
