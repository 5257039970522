import React from 'react';
import PropTypes from 'prop-types';

const FineCoarseIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      className={className}
      viewBox="0 0 33 33"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M3.5 20.198h9v9M29.5 12.198h-9v-9M20.5 12.198L31 1.698M2 30.698l10.5-10.5"
      />
      <path
        fill={color}
        d="M16.5 17.698c.828 0 1.5-.671 1.5-1.5 0-.828-.672-1.5-1.5-1.5s-1.5.672-1.5 1.5c0 .829.672 1.5 1.5 1.5z"
      />
    </svg>
  );
};

FineCoarseIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

FineCoarseIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default FineCoarseIcon;
