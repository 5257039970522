import React from 'react';
import PropTypes from 'prop-types';

const WorkflowIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      className={className}
      viewBox="0 0 70 45"
    >
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M18.555 23c2.485 0 4.5-2.015 4.5-4.5s-2.015-4.5-4.5-4.5c-2.486 0-4.5 2.015-4.5 4.5s2.014 4.5 4.5 4.5z"
      />
      <path
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M29.655 23c-.409.926-.213 2.007.495 2.73l.09.09c.563.563.88 1.326.88 2.122 0 .797-.317 1.56-.88 2.123-.563.563-1.327.88-2.123.88-.796 0-1.56-.317-2.122-.88l-.09-.09c-.724-.708-1.805-.904-2.73-.495-.907.389-1.496 1.279-1.5 2.265V32c0 1.657-1.344 3-3 3-1.657 0-3-1.343-3-3v-.135c-.024-1.016-.667-1.914-1.62-2.265-.926-.409-2.007-.213-2.73.495l-.09.09c-.563.563-1.327.88-2.123.88-.796 0-1.56-.317-2.122-.88-.564-.563-.88-1.326-.88-2.122 0-.797.316-1.56.88-2.123l.09-.09c.707-.723.903-1.804.495-2.73-.389-.907-1.279-1.496-2.265-1.5h-.255c-1.657 0-3-1.343-3-3s1.343-3 3-3h.135c1.016-.024 1.914-.666 2.265-1.62.408-.926.212-2.007-.495-2.73l-.09-.09c-.564-.563-.88-1.326-.88-2.123 0-.796.316-1.56.88-2.122.562-.563 1.326-.88 2.122-.88.796 0 1.56.317 2.123.88l.09.09c.723.707 1.804.904 2.73.495h.12c.906-.389 1.496-1.279 1.5-2.265V5c0-1.657 1.343-3 3-3s3 1.343 3 3v.135c.004.986.593 1.876 1.5 2.265.925.409 2.006.212 2.73-.495l.09-.09c.562-.563 1.326-.88 2.122-.88.796 0 1.56.317 2.123.88.563.563.88 1.326.88 2.122 0 .797-.317 1.56-.88 2.123l-.09.09c-.708.723-.904 1.804-.495 2.73V14c.388.907 1.278 1.496 2.265 1.5h.255c1.657 0 3 1.343 3 3s-1.343 3-3 3h-.135c-.987.004-1.877.593-2.265 1.5z"
        clipRule="evenodd"
      />
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M51.555 31c2.485 0 4.5-2.015 4.5-4.5s-2.015-4.5-4.5-4.5c-2.486 0-4.5 2.015-4.5 4.5s2.014 4.5 4.5 4.5z"
      />
      <path
        fillRule="evenodd"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M62.655 31c-.409.926-.213 2.007.495 2.73l.09.09c.563.563.88 1.326.88 2.123 0 .796-.317 1.56-.88 2.122-.563.563-1.327.88-2.123.88-.796 0-1.56-.317-2.122-.88l-.09-.09c-.724-.708-1.805-.904-2.73-.495-.907.389-1.496 1.279-1.5 2.265V40c0 1.657-1.343 3-3 3s-3-1.343-3-3v-.135c-.024-1.016-.666-1.914-1.62-2.265-.926-.409-2.007-.212-2.73.495l-.09.09c-.563.563-1.327.88-2.123.88-.796 0-1.56-.317-2.122-.88-.564-.563-.88-1.326-.88-2.123 0-.796.316-1.56.88-2.122l.09-.09c.707-.723.903-1.804.495-2.73-.389-.907-1.279-1.496-2.265-1.5h-.255c-1.657 0-3-1.343-3-3s1.343-3 3-3h.135c1.016-.024 1.914-.666 2.265-1.62.408-.926.212-2.007-.495-2.73l-.09-.09c-.564-.563-.88-1.326-.88-2.122 0-.797.316-1.56.88-2.123.562-.563 1.326-.88 2.122-.88.796 0 1.56.317 2.123.88l.09.09c.723.707 1.804.903 2.73.495h.12c.906-.389 1.496-1.278 1.5-2.265V13c0-1.657 1.343-3 3-3s3 1.343 3 3v.135c.004.986.593 1.876 1.5 2.265.925.409 2.006.213 2.73-.495l.09-.09c.562-.563 1.326-.88 2.122-.88.796 0 1.56.317 2.123.88.563.563.88 1.326.88 2.123 0 .796-.317 1.56-.88 2.122l-.09.09c-.708.723-.904 1.804-.495 2.73V22c.388.907 1.278 1.496 2.265 1.5h.255c1.656 0 3 1.343 3 3s-1.344 3-3 3h-.135c-.987.004-1.877.593-2.265 1.5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

WorkflowIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

WorkflowIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default WorkflowIcon;
