import React from 'react';
import PropTypes from 'prop-types';

const EducationIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      stroke={color}
      viewBox="0 0 82 56"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <path d="M79.338 16.444c-.003-1.718-1.64-3.305-4.296-4.164L44.969 2.653c-2.659-.86-5.934-.86-8.592 0L6.304 12.28c-2.656.859-4.293 2.446-4.296 4.164.003 1.718 1.64 3.305 4.296 4.163l30.073 9.628c2.658.86 5.933.86 8.592 0l30.073-9.628c2.656-.858 4.293-2.445 4.296-4.163z" />
      <path d="M16.328 26.996v18.331c0 4.795 10.82 8.665 24.345 8.665 13.525 0 24.345-3.87 24.345-8.665V26.996" />
      <path d="M75.709 53.08l.485-21.361c.094-3.383-1.831-6.055-5.05-7.01l-30.47-8.265" />
    </svg>
  );
};

EducationIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

EducationIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default EducationIcon;
