import React from 'react';
import PropTypes from 'prop-types';

const StopwatchFastIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      className={className}
      viewBox="0 0 40 61"
    >
      <g>
        <path
          fill={color}
          d="M18.5 9C11.044 9 5 15.044 5 22.5S11.044 36 18.5 36 32 29.956 32 22.5 25.956 9 18.5 9zM2 22.5C2 13.387 9.387 6 18.5 6S35 13.387 35 22.5 27.613 39 18.5 39 2 31.613 2 22.5z"
        />
        <path
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
          d="M2 45.067v14.867l9.557-7.434L2 45.067zM15 45.067v14.867l9.557-7.434L15 45.067zM28.5 45.067v14.867l9.557-7.434-9.557-7.433z"
        />
        <path
          fill={color}
          d="M18.5 8c-.828 0-1.5-.672-1.5-1.5v-5c0-.828.672-1.5 1.5-1.5S20 .672 20 1.5v5c0 .828-.672 1.5-1.5 1.5zM27.605 10.044c-.644-.52-.743-1.465-.222-2.11l1.341-1.656c.522-.644 1.466-.743 2.11-.222.644.522.743 1.466.222 2.11l-1.342 1.657c-.52.644-1.465.743-2.11.221zM18.5 24.433c-.829-.019-1.485-.705-1.467-1.533l.186-8.4c.018-.828.705-1.485 1.533-1.466.828.018 1.485.704 1.466 1.532l-.186 8.4c-.018.828-.704 1.485-1.532 1.467z"
        />
        <path
          fill={color}
          d="M14 1.5c0-.828.672-1.5 1.5-1.5h6c.828 0 1.5.672 1.5 1.5S22.328 3 21.5 3h-6c-.828 0-1.5-.672-1.5-1.5z"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h40v61H0V0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

StopwatchFastIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

StopwatchFastIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default StopwatchFastIcon;
