import React from 'react';
import PropTypes from 'prop-types';

const WizardSuccessIcon = ({ width, height, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      stroke={color}
      viewBox="0 0 376 250"
      className={className}
    >
      <g clipPath="url(#prefix__clip0)">
        <path
          fill="#4D3881"
          d="M219.009 49.86c-22.105-.785-43.157-7.92-63.247-15.71-20.089-7.79-40.014-16.459-61.59-20.595-13.876-2.66-29.748-3.036-40.926 4.402-10.762 7.173-14.239 19.52-16.107 30.988-1.407 8.628-2.234 17.706 1.619 25.783 2.677 5.608 7.425 10.322 10.71 15.694 11.432 18.689 3.35 41.736-9.033 59.984-5.806 8.563-12.552 16.736-17.036 25.841-4.485 9.106-6.558 19.553-2.634 28.85 3.887 9.222 13.153 16.134 23.191 21.001 20.386 9.885 44.4 12.716 67.833 14.318 51.856 3.548 103.985 2.011 155.978.474 19.242-.57 38.566-1.147 57.495-4.122 10.512-1.653 21.363-4.276 28.996-10.605 9.686-8.036 12.087-21.646 5.595-31.722-10.889-16.903-40.985-21.1-48.605-39.243-4.187-9.983.113-21.107 6.203-30.366 13.06-19.864 34.949-37.293 36.1-60.002.793-15.594-9.73-31.213-26.001-38.594-17.053-7.736-40.705-6.762-53.28 6.042-12.972 13.17-35.742 18.242-55.261 17.583z"
          opacity=".1"
        />
        <path
          fill="#4D3881"
          d="M58.492 250.001c13.81 0 25.005-2.153 25.005-4.809s-11.195-4.809-25.005-4.809c-13.809 0-25.004 2.153-25.004 4.809s11.195 4.809 25.004 4.809z"
        />
        <path
          fill="#3F3D56"
          d="M58.072 246.961c1.607 0 2.91-1.706 2.91-3.812 0-2.105-1.303-3.811-2.91-3.811-1.607 0-2.91 1.706-2.91 3.811 0 2.106 1.303 3.812 2.91 3.812z"
        />
        <path
          fill="#3F3D56"
          d="M58.072 242.301c1.607 0 2.91-1.707 2.91-3.812 0-2.105-1.303-3.811-2.91-3.811-1.607 0-2.91 1.706-2.91 3.811s1.303 3.812 2.91 3.812z"
        />
        <path
          fill="#3F3D56"
          d="M58.072 237.643c1.607 0 2.91-1.707 2.91-3.812 0-2.105-1.303-3.811-2.91-3.811-1.607 0-2.91 1.706-2.91 3.811s1.303 3.812 2.91 3.812z"
        />
        <path
          fill="#3F3D56"
          d="M58.072 232.988c1.607 0 2.91-1.706 2.91-3.811s-1.303-3.812-2.91-3.812c-1.607 0-2.91 1.707-2.91 3.812 0 2.105 1.303 3.811 2.91 3.811z"
        />
        <path
          fill="#3F3D56"
          d="M58.072 228.33c1.607 0 2.91-1.706 2.91-3.811s-1.303-3.812-2.91-3.812c-1.607 0-2.91 1.707-2.91 3.812 0 2.105 1.303 3.811 2.91 3.811z"
        />
        <path
          fill="#3F3D56"
          d="M58.072 223.67c1.607 0 2.91-1.706 2.91-3.812 0-2.105-1.303-3.811-2.91-3.811-1.607 0-2.91 1.706-2.91 3.811 0 2.106 1.303 3.812 2.91 3.812z"
        />
        <path
          fill="#3F3D56"
          d="M58.072 219.012c1.607 0 2.91-1.707 2.91-3.812 0-2.105-1.303-3.811-2.91-3.811-1.607 0-2.91 1.706-2.91 3.811s1.303 3.812 2.91 3.812z"
        />
        <path
          fill="#4D3881"
          d="M68.97 183.324c.4-.506.762-1.039 1.087-1.595l-7.65-1.257 8.271.062c.794-1.727 1.228-3.597 1.273-5.497.046-1.9-.296-3.789-1.006-5.552l-11.097 5.758 10.246-7.514c-.96-1.655-2.25-3.095-3.79-4.23-1.54-1.135-3.298-1.942-5.163-2.368-1.865-.427-3.799-.466-5.68-.113-1.88.352-3.668 1.088-5.252 2.161-1.584 1.074-2.93 2.461-3.955 4.077s-1.706 3.426-2.002 5.316c-.295 1.891-.198 3.822.285 5.673.483 1.852 1.342 3.584 2.524 5.089-.62.789-1.154 1.642-1.592 2.545l9.932 5.157-10.588-3.552c-.744 2.197-.929 4.546-.537 6.832.393 2.287 1.35 4.439 2.785 6.263-1.618 2.057-2.625 4.527-2.905 7.129-.28 2.602.178 5.231 1.32 7.585 1.144 2.354 2.927 4.339 5.145 5.728 2.218 1.388 4.783 2.125 7.4 2.125s5.181-.737 7.4-2.125c2.218-1.389 4-3.374 5.144-5.728 1.143-2.354 1.6-4.983 1.32-7.585-.28-2.602-1.286-5.072-2.904-7.129 1.934-2.458 2.985-5.495 2.985-8.622 0-3.128-1.051-6.165-2.985-8.623l-.01-.01z"
        />
        <path
          fill="#000"
          d="M44.08 191.941c-.005 3.127 1.047 6.165 2.984 8.62-1.618 2.057-2.625 4.528-2.905 7.13-.28 2.602.178 5.23 1.321 7.585 1.144 2.354 2.926 4.339 5.145 5.727 2.218 1.389 4.782 2.125 7.4 2.125 2.616 0 5.18-.736 7.399-2.125 2.218-1.388 4-3.373 5.144-5.727 1.143-2.355 1.601-4.983 1.321-7.585-.28-2.602-1.287-5.073-2.905-7.13 1.858-2.367-24.905-10.181-24.905-8.62z"
          opacity=".1"
        />
        <path
          fill="#3F3D56"
          d="M179.633 187.16c.211 2.732 1.263 5.56 3.476 7.172 2.214 1.613 5.735 1.582 7.426-.584.567-.813.985-1.721 1.232-2.681 1.425-4.501 2.436-9.426.875-13.883-3.521-.229-7.603-1.886-10.246 1.024-2.135 2.371-3.009 5.793-2.763 8.952z"
        />
        <path
          fill="#FABDBD"
          d="M195.979 159.622c-.468 3.973-1.264 7.928-1.182 11.927.031 1.544.195 3.074.198 4.624.006 4.027-1.054 7.983-3.074 11.466-.212.409-.497.777-.84 1.086-.809.656-2.029.601-2.937.099-.888-.552-1.609-1.335-2.087-2.264-2.544-4.358-2.616-9.656-3.624-14.598-.771-3.781-2.124-7.445-2.612-11.271-.318-2.462-.27-4.972-.776-7.404-.153-.734-.584-1.838-.867-2.92-.26-.967-.403-1.916-.137-2.569.543-1.335 2.323-.816 3.48-.734 4.078.302 8.172.292 12.248-.031.403-.03.861-.047 1.144.239.124.145.217.314.273.496.325.888.573 1.802.742 2.732.563 2.975.406 6.093.051 9.122z"
        />
        <g opacity=".1">
          <path
            fill="#000"
            d="M194.408 147.032c-.21-.022-.422-.022-.632 0-1.954.157-3.911.236-5.864.246 2.165.005 4.33-.077 6.496-.246zM188.834 188.824c-.888-.553-1.609-1.335-2.087-2.265-2.544-4.358-2.616-9.655-3.624-14.597-.772-3.781-2.124-7.446-2.612-11.271-.318-2.463-.27-4.973-.776-7.405-.153-.734-.584-1.837-.867-2.92-.26-.966-.403-1.916-.137-2.568.113-.268.304-.495.547-.653.244-.159.529-.24.819-.235-.864-.058-1.707.044-2.049.888-.266.652-.123 1.602.137 2.568.283 1.083.713 2.186.867 2.92.506 2.432.458 4.942.775 7.405.489 3.829 1.841 7.493 2.613 11.271 1.008 4.942 1.08 10.246 3.624 14.597.478.93 1.199 1.712 2.087 2.265.545.301 1.17.428 1.789.362-.388-.051-.763-.174-1.106-.362z"
            opacity=".1"
          />
        </g>
        <path
          fill="#FABDBD"
          d="M208.892 180.568c-2.049 4.098-1.707 9.904-1.707 9.904.341 13.662-5.124 42.351-5.124 42.351h-4.781s-.683-31.763 0-34.495c.683-2.733-.342-12.296-1.366-25.616-.762-9.904 1.311-18.88 2.425-22.818.382-1.366.649-2.114.649-2.114s7.855-11.613 12.295-6.148c1.025 1.271 1.568 3.836 1.756 7.073.607 10.684-2.576 28.717-4.147 31.863z"
        />
        <path
          fill="#000"
          d="M213.039 148.707c-.188-3.238-.724-5.806-1.756-7.073-.99-1.216-2.151-1.585-3.354-1.425.932.155 1.767.666 2.33 1.425 1.024 1.27 1.567 3.835 1.755 7.073.608 10.683-2.575 28.716-4.146 31.862-2.049 4.098-1.708 9.905-1.708 9.905.342 13.661-5.123 42.351-5.123 42.351h1.025s5.464-28.69 5.123-42.351c0 0-.342-5.807 1.708-9.905 1.571-3.146 4.754-21.179 4.146-31.862z"
          opacity=".1"
        />
        <path
          fill="#3F3D56"
          d="M194.794 231.578c-.382.3-.712.66-.977 1.066-.612 1.154.256 2.534 1.185 3.453s2.049 1.902 2.049 3.21c0 1.052-.796 1.954-1.687 2.514-.478.301-1.079.683-.98 1.25.034.143.096.279.181.4.765 1.171 2.121 1.858 3.494 2.117 1.373.26 2.79.147 4.187.034.209.008.414-.063.574-.198.101-.136.155-.301.153-.471.069-.724.024-1.489.342-2.141.444-.892 1.465-1.346 2.049-2.145.82-1.093.707-2.613.396-3.942-.683-2.893-2.203-6.192-4.484-8.197-2.644-2.302-4.717 1.387-6.482 3.05zM205.142 59.424c1.906-2.849 1.411-6.83-.536-9.666-1.947-2.834-5.075-4.617-8.303-5.768-4.351-1.55-9.15-2.08-13.621-.916-3.558.926-6.755 2.863-10.109 4.358-5.934 2.648-12.385 3.932-18.88 3.757-7.21-.201-15.11-2.049-21.159 1.893-11.072 7.203-6.147 26.807-16.622 34.837-2.825 2.165-6.38 3.111-9.523 4.781-3.142 1.67-6.12 4.577-6.045 8.136.034 1.707.786 3.336 1.708 4.781 3.928 6.226 11.271 10.079 18.624 10.007 7.353-.071 14.529-3.982 18.768-10 1.062-1.506 1.998-3.183 3.538-4.187 3.074-2.015 7.124-.605 10.793-.304 8.166.666 16.335-5.465 17.961-13.508.683-3.433.564-7.534 3.327-9.69 2.23-1.738 5.369-1.366 8.197-1.663 5.171-.536 9.252-3.757 12.059-7.965 1.288-1.93 1.438-4.235 3.194-5.755 1.892-1.67 4.819-.437 6.629-3.128z"
        />
        <g opacity=".1">
          <path
            fill="#000"
            d="M146.862 51.85c2.995.082 5.99-.147 8.938-.684-.813.024-1.633.024-2.449 0-5.659-.157-11.742-1.325-17.022.083 3.483.034 7.087.505 10.533.6zM136.144 102.117c-1.54 1.004-2.476 2.681-3.538 4.187-3.265 4.583-8.036 7.872-13.481 9.293.4.017.8.028 1.203.024 7.36-.072 14.529-3.982 18.767-10 1.062-1.506 1.998-3.183 3.539-4.187l.266-.164c-2.411-.332-4.778-.448-6.756.847zM204.261 49.749c-1.944-2.822-5.075-4.618-8.303-5.769-4.351-1.55-9.15-2.08-13.621-.915-.102.024-.201.054-.3.082 2.538.141 5.041.652 7.432 1.516 3.227 1.151 6.359 2.948 8.303 5.769 1.943 2.82 2.442 6.83.536 9.665-1.81 2.702-4.737 1.469-6.653 3.132-1.756 1.52-1.906 3.825-3.194 5.755-2.414 3.61-5.775 6.49-9.959 7.582 1.438-.17 2.954-.15 4.389-.3 5.171-.537 9.252-3.757 12.059-7.965 1.288-1.93 1.438-4.235 3.194-5.755 1.916-1.663 4.843-.43 6.653-3.132 1.909-2.848 1.414-6.844-.536-9.665zM176.405 76.95c-2.811.293-5.949-.076-8.197 1.662-2.763 2.156-2.63 6.258-3.326 9.69-1.199 6-6.069 10.95-11.852 12.791l.407.037c8.166.666 16.336-5.465 17.961-13.508.683-3.432.564-7.534 3.327-9.69.634-.474 1.355-.822 2.121-1.024-.154.004-.294.028-.441.041z"
            opacity=".1"
          />
        </g>
        <path
          fill="#FABDBD"
          d="M258.928 47.197s-8.197 16.394-10.929 22.541c-2.733 6.148-27.665 15.37-31.081 15.711-.916.126-1.769.537-2.438 1.175-1.93 1.708-3.026 4.632-3.026 4.632s1.366 9.221-.342 24.59c-.034.297-.065.595-.092.881-1.489 14.625 1.629 18.075 1.629 18.075s-33.129 12.637-32.105-3.757c.322-5.161.274-8.931-.044-12.084-.348-3.227-1.004-6.413-1.96-9.515-.81-2.832-1.776-5.936-2.777-10.164-.482-2.11-1.116-4.182-1.899-6.2-.024-.06-.048-.122-.068-.18-1.708-4.239-3.416-5.608-4.393-6.052-.922-.402-1.801-.896-2.623-1.476-3.155-2.278-9.928-7.384-15.96-13.415-8.197-8.197-16.394-27.665-16.394-27.665s-2.39-11.271 3.074-20.492c5.465-9.222 3.074 18.101 3.074 18.101s9.905 13.32 12.296 19.468c1.229 3.162 3.183 4.781 4.781 5.622 2.641 1.374 5.169 2.955 7.562 4.726 1.617 1.222 3.316 2.332 5.085 3.32 3.819 2.05 8.392 4.362 12.203 4.97.596.098 1.197.15 1.8.153.287.01.571-.047.831-.166.261-.118.491-.295.672-.517 1.025-1.205 1.001-3.801.765-5.936-.126-1.106-.313-2.204-.56-3.289 0 0 11.1-7.001 10.759 1.537-.053.944.034 1.89.259 2.808.182.842.584 1.621 1.165 2.257.582.636 1.322 1.107 2.145 1.363 1.332.39 2.743.437 4.098.133.423-.072.831-.212 1.209-.413 1.025-.683 11.271-3.757 14.345-4.099 3.074-.341 18.443-7.855 18.443-7.855l16.735-23.225s-.341-13.661 6.49-19.126c6.83-5.465-2.729 23.563-2.729 23.563z"
        />
        <g opacity=".1">
          <path
            fill="#000"
            d="M211.02 114.678c-.298 2.744-.429 5.505-.392 8.265.034-1.803.153-3.863.392-6.216.028-.287.058-.584.093-.881.597-5.26.882-10.55.853-15.844-.052 4.61-.337 9.214-.853 13.795-.021.297-.065.594-.093.881zM186.364 70.035c-.228.133-.341.215-.341.215.332 1.461.551 2.946.656 4.44.069-1.558-.037-3.12-.315-4.655zM140.735 40.047c.167-2.39.416-6.677.369-10.488-.058 5.095-.513 10.294-.513 10.294l.144.194zM180.569 130.645c0 .133 0 .266-.024.399-1.025 16.394 32.104 3.757 32.104 3.757-.387-.523-.679-1.11-.864-1.735-4.99 1.776-30.065 10.034-31.216-2.421zM258.928 45.147s-8.197 16.394-10.929 22.542c-2.732 6.147-27.665 15.369-31.08 15.71-.916.126-1.77.537-2.439 1.175-1.93 1.708-3.026 4.632-3.026 4.632s.082.556.181 1.615c.383-.878 1.366-2.893 2.845-4.197.669-.639 1.523-1.05 2.439-1.175 3.415-.342 28.348-9.564 31.08-15.711 2.732-6.148 10.929-22.542 10.929-22.542s5.776-17.538 5.14-22.784c-.526 6.722-5.14 20.735-5.14 20.735zM180.5 116.911c-.347-3.227-1.004-6.414-1.96-9.516-.81-2.831-1.776-5.936-2.777-10.164-.482-2.11-1.116-4.181-1.899-6.199-.024-.061-.048-.123-.068-.18-1.708-4.24-3.416-5.609-4.392-6.053-.923-.401-1.802-.895-2.623-1.475-3.156-2.278-9.929-7.384-15.961-13.416-8.197-8.197-16.393-27.665-16.393-27.665-.303-1.643-.472-3.309-.506-4.98-.083 2.355.086 4.711.506 7.03 0 0 8.196 19.467 16.393 27.664 6.032 6.032 12.805 11.138 15.961 13.416.828.581 1.714 1.075 2.643 1.475.973.444 2.678 1.814 4.392 6.052.021.058.045.12.069.181.779 2.018 1.41 4.09 1.888 6.2 1.001 4.228 1.968 7.332 2.777 10.164.956 3.101 1.613 6.288 1.96 9.515.144 1.428.229 2.982.253 4.73.042-2.263-.046-4.526-.263-6.779z"
            opacity=".1"
          />
        </g>
        <path
          fill="#4D3881"
          d="M214.326 102.075c-.521.862-.978 1.762-1.366 2.692-.516 1.434-.519 2.995-.615 4.515-.136 2.133-.461 4.249-.97 6.325-.082.382-.196.757-.341 1.12-.102.245-.237.475-.4.683-.182.223-.396.418-.635.578-.367.244-.754.455-1.158.631-4.754 2.22-9.939 4.181-15.127 3.416-2.193-.384-4.315-1.098-6.294-2.118-1.879-.912-4.003-1.994-5.95-1.236-.299.152-.621.252-.953.297-.064.001-.128-.005-.191-.017-.642-.123-.642-2.288-.789-2.927-.56-2.452-.646-4.44-.98-6.571-.178-1.179-.454-2.341-.823-3.474-1.367-4.132-1.78-8.538-1.78-12.869 0-.215-1.537 0-2.049-.02-.13 0-.202-.02-.171-.065.03-.043.064-.082.103-.116.178-.146.387-.25.611-.304l.403-.12c2.111-.598 4.44-.847 6.018-2.333 1.639-1.533 2.049-3.982 1.977-6.222-.02-.721-.372-2.47-.314-3.921.595.098 1.197.15 1.8.153.286.01.571-.047.831-.166.261-.118.491-.295.672-.517.57 1.103 1.063 2.245 1.475 3.416.581 1.427 1.025 2.93 1.875 4.221 1.367 2.104 3.887 3.487 6.394 3.275 2.507-.211 4.84-2.138 5.287-4.61.393-2.17-.519-5.29-.499-7.545 1.333.391 2.743.437 4.099.133.057.14.106.282.147.427.768 2.637 1.793 5.663 4.371 6.599 1.667.6 3.638.15 5.178 1.024.118.068.233.144.342.226.355.264.662.59.905.96.633 1.044 1.008 2.224 1.096 3.442.394 3.808-.365 7.647-2.179 11.018z"
        />
        <path
          fill="#000"
          d="M197.027 74.595c-1.703.657-3.538.904-5.355.72-1.816-.183-3.564-.791-5.103-1.775-.125-1.106-.312-2.204-.56-3.29 0 0 11.1-7 10.759 1.538-.053.943.034 1.89.259 2.807z"
          opacity=".1"
        />
        <path
          fill="#FABDBD"
          d="M192.836 74.692c6.414 0 11.613-5.2 11.613-11.613 0-6.413-5.199-11.612-11.613-11.612-6.413 0-11.612 5.199-11.612 11.612 0 6.414 5.199 11.613 11.612 11.613z"
        />
        <path
          fill="#57A50D"
          d="M174.201 94.621s21.859-14.344 91.874 1.025c70.016 15.369 109.976 3.074 109.976 3.074l.342 14.686s-77.871 8.539-107.927 0c-30.055-8.538-72.499-14.488-87.482-1.267l-6.783-17.518z"
        />
        <path
          fill="#000"
          fillOpacity=".19"
          d="M174.201 94.621s21.859-14.344 91.874 1.025c70.016 15.369 109.976 3.074 109.976 3.074l.342 14.686s-77.871 8.539-107.927 0c-30.055-8.538-72.499-14.488-87.482-1.267l-6.783-17.518z"
        />
        <path
          fill="#57A50D"
          d="M165.984 116.686s-22.541 14.345-92.557-1.025c-70.016-15.369-66.6-3.074-66.6-3.074V96.535s34.495-8.538 64.55 0c30.056 8.539 72.841 13.464 87.824.243l6.783 19.908z"
        />
        <path
          fill="#000"
          fillOpacity=".19"
          d="M165.984 116.686s-22.541 14.345-92.557-1.025c-70.016-15.369-66.6-3.074-66.6-3.074V96.535s34.495-8.538 64.55 0c30.056 8.539 72.841 13.464 87.824.243l6.783 19.908z"
        />
        <path
          fill="#57A50D"
          d="M173.201 92.621s21.859-14.344 91.874 1.025c70.016 15.369 109.976 3.074 109.976 3.074l.342 14.686s-77.871 8.539-107.927 0c-30.055-8.538-72.499-14.488-87.482-1.267l-6.783-17.518zM164.984 114.686s-22.541 14.345-92.557-1.025c-70.016-15.369-66.6-3.074-66.6-3.074V94.535s34.495-8.538 64.55 0c30.056 8.539 72.841 13.464 87.824.243l6.783 19.908z"
        />
        <path
          fill="#3F3D56"
          d="M182.85 65.001c-.342-2.131-.868-4.815.915-6.035.636-.385 1.371-.575 2.114-.546 3.692-.038 7.22 2.534 10.841 1.783 1.152-.242 2.21-.81 3.046-1.64.837-.828 1.416-1.88 1.668-3.03.253-1.15.168-2.348-.244-3.45-.413-1.103-1.135-2.063-2.079-2.765-1.425-1.059-3.225-1.441-4.966-1.8-4.58-.943-9.362-1.882-13.884-.683-4.522 1.199-8.655 5.253-8.293 9.904.171 2.227 1.305 4.25 2.49 6.148.902 1.441 4.413 8.197 6.117 8.3 2.405.14 2.511-4.792 2.275-6.186z"
        />
        <path
          fill="#4D3881"
          d="M211.625 128.996s-23.566 5.806-31.422 0c0 0-3.415 13.32-2.391 20.151 0 0 16.736 1.025 18.443 2.049 0 0 .342-2.732 2.05-1.366 0 0 5.806-6.831 16.394-.683 0 0 2.049-16.052-3.074-20.151z"
        />
        <path
          fill="#000"
          d="M199.118 49.318c-.244-.176-.498-.336-.762-.478.705.801 1.187 1.773 1.398 2.82.211 1.045.144 2.128-.194 3.14-.338 1.012-.937 1.918-1.735 2.626s-1.768 1.195-2.813 1.41c-3.62.752-7.148-1.82-10.84-1.782-.743-.03-1.479.16-2.114.546-1.783 1.22-1.261 3.904-.919 6.035.225 1.394.133 6.326-2.265 6.192h-.041c.66.803 1.274 1.346 1.749 1.367 2.391.133 2.49-4.782 2.265-6.193-.342-2.13-.865-4.815.918-6.035.636-.385 1.371-.575 2.114-.546 3.692-.038 7.221 2.534 10.841 1.783 1.152-.242 2.21-.81 3.046-1.64.837-.828 1.416-1.88 1.669-3.03.252-1.15.167-2.348-.245-3.45-.412-1.103-1.134-2.063-2.079-2.765h.007z"
          opacity=".1"
        />
        <path
          fill="#4D3881"
          d="M29.495 33.094S3.958 22.356.611 41.721c0 0-1.745 3.016.683 5.51 0 0 .523-1.336 2.992.047.882.485 1.817.864 2.787 1.13 1.311.362 2.704.288 3.969-.211 0 0 8.398-.454 15.461-11.442 0 0 3.026-1.871 3.18-2.571l-5.061.341s.386 3.706-1.708 5.93c0 0 1.861-5.749.966-5.913-.18-.034-2.674.297-2.674.297s.646 6.442-2.732 9.775c0 0 3.135-6.554 1.769-9.658l-3.733.72s1.025 6.776-2.67 10.721c0 0 3.035-6.967 1.864-10.51l-3.58 1.203s.865 6.633-2.226 9.905c0 0 2.698-8.405 1.428-9.563 0 0-3.218 1.304-3.908 2.049 0 0 .513 4.782-1.455 6.49 0 0 1.21-5.807.612-6.046 0 0-3.74 2.814-4.878 5.243 0 0 1.421-3.71 4.406-5.807 0 0-2.507-.423-4.44.625 0 0 1.295-2.462 5.345-1.294 0 0 3.003-2.077 3.604-2.05 0 0-3.832-1.707-6.602-1.499 0 0 2.968-1.707 7.54 1.148L15.117 35s-4.948-2.538-7.431-2.528c0 0 3.299-1.273 8.36 2.279l3.652-.748s-4.099-2.51-6.862-3.095c0 0 3.316-.522 7.917 2.924l2.5-.212s-3.026-1.78-3.941-2.213c-.916-.434-.875-.717-.875-.717 2.179.378 4.21 1.355 5.864 2.82 0 0 5.171-.098 5.195-.416zM28.553 13.845S19.488-7.08 6.479 2.516c0 0-2.756.8-2.732 3.655 0 0 1.072-.495 1.748 1.732.253.79.597 1.55 1.025 2.26.566.957 1.428 1.704 2.456 2.128 0 0 5.246 4.515 15.71 2.012 0 0 2.866.615 3.358.283l-3.2-2.684s-1.879 2.421-4.4 2.541c0 0 4.383-2.357 3.942-2.965-.089-.123-1.755-1.345-1.755-1.345s-3.29 4.194-7.173 4.259c0 0 5.598-2.11 6.554-4.73l-2.626-1.708S16.128 12.56 11.69 12.8c0 0 5.772-2.39 7.094-5.178l-2.811-1.325s-3.265 4.44-6.957 4.608c0 0 6.39-3.457 6.29-4.867 0 0-2.653-1.06-3.493-.998 0 0-2.415 3.126-4.57 3.037 0 0 4.027-2.763 3.805-3.245 0 0-3.825-.458-5.885.342 0 0 2.958-1.394 5.926-.936 0 0-1.247-1.684-2.985-2.152 0 0 2.172-.727 3.914 2.275 0 0 2.964.474 3.31.83 0 0-1.299-3.207-3.075-4.652 0 0 2.733.683 3.826 4.976l2.852 1.267s-1.493-4.327-2.992-5.73c0 0 2.688 1.12 3.671 6.113L22.203 8.8s-1.007-3.825-2.312-5.731c0 0 2.268 1.574 3.04 6.243l1.605 1.298s-.785-2.78-1.082-3.559c-.298-.779-.11-.922-.11-.922 1.077 1.467 1.726 3.204 1.875 5.017 0 0 3.15 2.876 3.334 2.698z"
          opacity=".1"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0H375.731V250H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

WizardSuccessIcon.defaultProps = {
  color: 'none',
  width: '376',
  height: '250',
  className: ''
};

WizardSuccessIcon.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default WizardSuccessIcon;
