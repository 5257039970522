import React from 'react';
import PropTypes from 'prop-types';

const EndEffectorIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      stroke={color}
      viewBox="0 0 36 37"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={className}
    >
      <path d="M4.5 28v-9c0-3.58 1.422-5.468 3.954-8C9.891 9.563 11.62 8.165 13.5 7.5m18 20.5v-9c0-3.58-1.422-5.468-3.954-8-1.437-1.437-3.165-2.835-5.046-3.5" />
      <path d="M18 10.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9zM31.5 29.5a3 3 0 0 1-3 3H27a3 3 0 0 1-3-3V25a3 3 0 0 1 3-3h4.5v7.5zm-27 0a3 3 0 0 0 3 3H9a3 3 0 0 0 3-3V25a3 3 0 0 0-3-3H4.5v7.5z" />
    </svg>
  );
};

EndEffectorIcon.defaultProps = {
  color: 'currentColor',
  size: '24',
  className: ''
};

EndEffectorIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default EndEffectorIcon;
