import React from 'react';
import PropTypes from 'prop-types';

const ReachSmallIcon = ({ size, className, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={className}
      fill="none"
      viewBox="0 0 80 80"
    >
      <path
        d="M45.5 55C45.5 57.4853 47.5147 59.5 50 59.5C52.4853 59.5 54.5 57.4853 54.5 55C54.5 52.5147 52.4853 50.5 50 50.5C47.5147 50.5 45.5 52.5147 45.5 55Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35 37C35 39.4853 37.0147 41.5 39.5 41.5C41.9853 41.5 44 39.4853 44 37C44 34.5147 41.9853 32.5 39.5 32.5C37.0147 32.5 35 34.5147 35 37Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5 55C24.5 57.4853 26.5147 59.5 29 59.5C31.4853 59.5 33.5 57.4853 33.5 55C33.5 52.5147 31.4853 50.5 29 50.5C26.5147 50.5 24.5 52.5147 24.5 55Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.2349 40.8848L31.2649 51.1298"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M40 55L39 55" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M47.7349 51.1148L41.7649 40.8848"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

ReachSmallIcon.defaultProps = {
  color: 'currentColor',
  size: '1em',
  className: ''
};

ReachSmallIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};

export default ReachSmallIcon;
